import React, { useContext, useEffect, useState } from 'react'
import { Button, TextField, Collapse } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { SHA256 } from 'crypto-js'
import { Dialog } from './Dialog'
import { GlobalContext } from 'src/App'
import { config } from 'src/config'
import { API } from 'src/api'
import LoaderButton from './LoaderButton'
import controlStyles from './DialogControls.module.scss'
import styles from './LoginDialog.module.scss'

export const LoginDialog: React.FC = () => {
  const { currentDialog, setCurrentDialog } = useContext(GlobalContext)
  const [error, setError] = useState(false)
  const [authError, setAuthError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function autoLogin(sessionKey: string) {
      const res = await API.Account.autoLogin(sessionKey)
      if (res.status.result === 'ok') {
        window.location.replace(config.appURL)
      }
    }
    const sessionKeyPosition = document.cookie.indexOf('session_key')
    if (currentDialog === 'login' && sessionKeyPosition != -1) {
      const sessionKey = document.cookie.substr(sessionKeyPosition + 'session_key'.length + 1, 64)
      autoLogin(sessionKey)
    }
  }, [currentDialog])

  function handleClose() {
    setCurrentDialog('none')
    setTimeout(() => {
      setError(false)
      setAuthError(false)
      setErrorText('')
      setEmail('')
      setPassword('')
      setEmailError(false)
      setPasswordError(false)
      setLoading(false)
    }, 200)
  }

  function onEmailChange(v: string) {
    setEmail(v)
    setEmailError(false)
  }

  function onPasswordChange(v: string) {
    setPassword(v)
    setPasswordError(false)
  }

  function validateFields() {
    if (email === '' || password === '') {
      setEmailError(email === '')
      setPasswordError(password === '')
      setErrorText('Mindkét mező kitöltése kötelező!')
      setError(true)
      return false
    }
    const emailRegex = /.+@.+..+/
    if (!email.match(emailRegex)) {
      setEmailError(true)
      setErrorText('Helytelen e-mail cím!')
      setError(true)
      return false
    }
    return true
  }

  function handleRegisterHelp() {
    handleClose()
    setCurrentDialog('register')
  }

  function handleResetPassword() {
    handleClose()
    setCurrentDialog('resetPassword')
  }

  async function handleLogin() {
    if (!loading && validateFields()) {
      setError(false)
      setTimeout(() => setAuthError(false), 200)
      setEmailError(false)
      setPasswordError(false)
      setLoading(true)
      const passwordHash = SHA256(password).toString()
      const res = await API.Account.login(email, passwordHash)
      setTimeout(() => {
        setLoading(false)
        if (res.status.result === 'ok') {
          let date = new Date()
          date.setFullYear(date.getFullYear() + 1)
          document.cookie =
            'session_key=' +
            res.sessionKey +
            '; expires=' +
            date.toUTCString() +
            '; path=/; domain=orvosvalasz.hu'
          window.location.replace(config.appURL)
        } else {
          if (res.status.message === 'not verified') {
            setErrorText('Ez az e-mail cím nincs megerősítve. Ellenőrizze beérkető üzeneteit!')
            setEmailError(true)
          } else {
            setEmailError(true)
            setPasswordError(true)
            setErrorText('Helytelen e-mail cím vagy jelszó!')
            setAuthError(true)
          }
          setError(true)
        }
      }, 400)
    }
  }

  return (
    <Dialog isOpen={currentDialog === 'login'} onClose={handleClose}>
      <div className={controlStyles.top}>
        <h1 className={controlStyles.title}>Bejelentkezés</h1>
        <Button className={controlStyles.button} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>

      <div className={controlStyles.middle}>
        <TextField
          className={controlStyles.textField}
          disabled={loading}
          label='E-mail'
          variant='outlined'
          type='email'
          placeholder='minta.peter@domain.com'
          error={emailError}
          onChange={(x) => onEmailChange(x.target.value)}
        />

        <TextField
          className={controlStyles.textField}
          disabled={loading}
          label='Jelszó'
          variant='outlined'
          type='password'
          placeholder='••••••••••••'
          error={passwordError}
          onChange={(x) => onPasswordChange(x.target.value)}
        />

        <Collapse in={error}>
          <Alert className={controlStyles.alert} severity='error'>
            {
              <>
                <div>{errorText}</div>
                {authError ? (
                  <Button onClick={handleResetPassword} className={styles.forgottenPass}>
                    Jelszó visszaállítása
                  </Button>
                ) : null}
              </>
            }
          </Alert>
        </Collapse>

        <div className={styles.registerHelp}>
          <p>Itt bejelentkezhet személyes OrvosVálasz fiókjába.</p>
          <p className={styles.inlineLink} onClick={handleRegisterHelp}>
            Még nincs fiókja?
          </p>
        </div>

        <div className={controlStyles.buttonContainer}>
          <LoaderButton loading={loading} onClick={handleLogin}>
            Bejelentkezés
          </LoaderButton>
          <Button onClick={handleClose} className={`${controlStyles.button}`}>
            Mégse
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
