import React from 'react'
import { Modal, Fade, Backdrop, useMediaQuery } from '@material-ui/core'
import { ScreenLockPortrait } from '@material-ui/icons'

import styles from './Dialog.module.scss'

interface Props {
  isOpen: boolean
  onClose?: () => void
}

export const Dialog: React.FC<Props> = ({ children, isOpen, onClose }) => {
  const landscapeCutoff = useMediaQuery('(max-height: 480px)')

  // if (landscapeCutoff)
  //   return (
  //     <Modal
  //       open={isOpen}
  //       onClose={onClose}
  //       closeAfterTransition
  //       className={styles.modal}
  //       BackdropComponent={Backdrop}
  //       BackdropProps={{
  //         timeout: 200,
  //       }}
  //     >
  //       <Fade in={isOpen}>
  //         <div onClick={onClose} className={styles.landscapeCutoff}>
  //           <ScreenLockPortrait className={styles.icon} />
  //           <h3>
  //             Az oldal használatához kérjük, <br />
  //             forgassa készülékét álló módba!
  //           </h3>
  //         </div>
  //       </Fade>
  //     </Modal>
  //   )

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      className={styles.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={isOpen}>
        <div className={styles.content}>{children}</div>
      </Fade>
    </Modal>
  )
}
