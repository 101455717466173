import React, { ReactElement, useContext } from 'react'
import { Button, useMediaQuery } from '@material-ui/core'
import { AccountCircle, PersonAdd, InfoOutlined } from '@material-ui/icons'
import { GlobalContext } from 'src/App'

import styles from './Menu.module.scss'

interface MenuButtonProps {
  icon?: ReactElement
  onClick: () => void
}

const MenuButton: React.FC<MenuButtonProps> = ({ children, icon, onClick }) => {
  return (
    <Button onClick={onClick} startIcon={icon} className={styles.menuButton}>
      {children}
    </Button>
  )
}

interface Props {
  isOpen: boolean
  onItemSelected?: () => void
}

export const Menu: React.FC<Props> = ({ isOpen, onItemSelected }) => {
  const { setCurrentDialog } = useContext(GlobalContext)
  const isSmallScreen = useMediaQuery('screen and (max-width: 500px)')
  const isTinyScreen = useMediaQuery('screen and (max-width: 360px)')

  function onInfoButton() {
    const scrollTarget = document.getElementById('firstSection')?.offsetTop
    if (scrollTarget) {
      const offset = isSmallScreen ? 100 : 220
      window.scrollTo({ behavior: 'smooth', top: scrollTarget - offset })
    }
    onItemSelected?.()
  }

  return (
    <>
      <div className={`${styles.container} ${isOpen ? styles.visible : styles.hidden}`}>
        {isTinyScreen ? (
          <MenuButton
            onClick={() => {
              setCurrentDialog('login')
              onItemSelected?.()
            }}
            icon={<AccountCircle />}
          >
            Bejelentkezés
          </MenuButton>
        ) : null}
        {isSmallScreen ? (
          <>
            <MenuButton
              onClick={() => {
                setCurrentDialog('register')
                onItemSelected?.()
              }}
              icon={<PersonAdd />}
            >
              Regisztráció
            </MenuButton>
            <div className={styles.menuDivider} />
          </>
        ) : null}
        <MenuButton onClick={onInfoButton} icon={<InfoOutlined />}>
          Tudjon meg többet!
        </MenuButton>
      </div>
    </>
  )
}
