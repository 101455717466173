import React, { useContext } from 'react'
import { Button, useMediaQuery } from '@material-ui/core'
import { GlobalContext } from 'src/App'
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons'

import styles from './NavBar.module.scss'

interface ButtonProps {
  onClick: () => void
  variant?: 'menu' | 'highlight'
  style: 'opaque' | 'transparent'
}

const NavBarButton: React.FC<ButtonProps> = ({ children, onClick, variant, style }) => {
  let variantStyle = null
  if (variant) {
    variantStyle = variant === 'highlight' ? styles.highlight : styles.menuButton
  }

  return (
    <Button
      className={`${styles.button} ${variantStyle} ${style === 'opaque' ? styles.alt : null}`}
      variant='text'
      disableElevation
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

interface Props {
  style: 'transparent' | 'opaque'
  menuOpen: boolean
  onMenuClick: () => void
  onLogoClick: () => void
}

export const NavBar: React.FC<Props> = ({ menuOpen, style, onMenuClick, onLogoClick }) => {
  const { setCurrentDialog } = useContext(GlobalContext)

  return (
    <nav className={`${style === 'opaque' ? styles.opaque : styles.transparent}`}>
      <img
        className={styles.logo}
        draggable={false}
        src={process.env.PUBLIC_URL + '/logo/ov_logo.svg'}
        onClick={onLogoClick}
      />
      <div className={styles.buttonContainer}>
        <NavBarButton style={style} onClick={() => setCurrentDialog('register')}>
          Regisztráció
        </NavBarButton>

        <NavBarButton style={style} variant='highlight' onClick={() => setCurrentDialog('login')}>
          Bejelentkezés
        </NavBarButton>

        <NavBarButton style={style} variant='menu' onClick={onMenuClick}>
          {menuOpen ? <CloseIcon /> : <MenuIcon />}
        </NavBarButton>
      </div>
    </nav>
  )
}
