import React, { useContext, useState } from 'react'
import {
  Button,
  TextField,
  Collapse,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core'
import { SHA256 } from 'crypto-js'
import { API } from 'src/api'
import { config } from 'src/config'
import { Alert } from '@material-ui/lab'
import { Close as CloseIcon } from '@material-ui/icons'
import { Dialog } from './Dialog'
import { GlobalContext } from 'src/App'
import LoaderButton from './LoaderButton'
import controlStyles from './DialogControls.module.scss'
import styles from './RegisterDialog.module.scss'

export const RegisterDialog: React.FC = () => {
  const { currentDialog, setCurrentDialog, setLastEmail } = useContext(GlobalContext)
  const [accountType, setAccountType] = useState<'user' | 'doctor'>('user')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verificationID, setVerificationID] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [verificationIDError, setVerificationIDError] = useState(false)

  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')

  const [loading, setLoading] = useState(false)

  function handleClose() {
    setCurrentDialog('none')
    setAccountType('user')
    setEmail('')
    setPassword('')
    setVerificationID('')
    setTermsAccepted(false)
    setPrivacyPolicyAccepted(false)
    setEmailError(false)
    setPasswordError(false)
    setVerificationIDError(false)
    setError(false)
    setErrorText('')
  }

  function onEmailChange(v: string) {
    setEmail(v)
    setEmailError(false)
  }

  function onPasswordChange(v: string) {
    setPassword(v)
    setPasswordError(false)
  }

  function onVerificationIDChange(v: string) {
    setVerificationID(v)
    setVerificationIDError(false)
  }

  function onTermsAcceptedChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTermsAccepted(event.target.checked)
  }

  function onPrivacyPolicyAcceptedChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPrivacyPolicyAccepted(event.target.checked)
  }

  function handleAccountTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAccountType(event.target.value as 'user' | 'doctor')
  }

  function validateFields() {
    if (accountType === 'user') {
      if (email === '' || password === '') {
        setEmailError(email === '')
        setPasswordError(password === '')
        setErrorText('Mindkét mező kitöltése kötelező!')
        setError(true)
        return false
      }
    } else {
      if (email === '' || password === '' || verificationID === '') {
        setEmailError(email === '')
        setPasswordError(password === '')
        setVerificationIDError(verificationID === '')
        setErrorText('Mindhárom mező kitöltése kötelező!')
        setError(true)
        return false
      }
    }

    const emailRegex = /.+@.+..+/
    if (!email.match(emailRegex)) {
      setEmailError(true)
      setErrorText('Helytelen e-mail cím!')
      setError(true)
      return false
    }

    if (password.length < 8) {
      setPasswordError(true)
      setErrorText('Legalább 8 karakteres jelszó szükséges!')
      setError(true)
      return false
    }

    if (accountType === 'doctor' && verificationID.length < 5) {
      setVerificationIDError(true)
      setErrorText('Hibás pecsétszám!')
      setError(true)
      return false
    }

    if (!termsAccepted || !privacyPolicyAccepted) {
      setErrorText('Mindkét nyilatkozat szükséges!')
      setError(true)
      return false
    }

    return true
  }

  async function handleRegister() {
    if (!loading && validateFields()) {
      setError(false)
      setEmailError(false)
      setPasswordError(false)
      setLoading(true)
      const passwordHash = SHA256(password).toString()
      const res = await API.Account.register(
        email,
        passwordHash,
        accountType === 'doctor',
        verificationID
      )
      setTimeout(() => {
        setLoading(false)
        if (res.status.result === 'ok') {
          if (accountType === 'doctor') {
            let date = new Date()
            date.setFullYear(date.getFullYear() + 1)
            document.cookie =
              'session_key=' +
              res.sessionKey +
              '; expires=' +
              date.toUTCString() +
              '; path=/; domain=orvosvalasz.hu'
            window.location.replace(config.appURL)
          } else {
            handleClose()
            setLastEmail(email)
            setCurrentDialog('confirmEmail')
          }
        } else {
          if (res.status.message === 'already registered') {
            setErrorText('Ez az e-mail már használatban van!')
            setEmailError(true)
          } else {
            setEmailError(true)
            setPasswordError(true)
            setErrorText('Sikertelen regisztráció!')
          }
          setError(true)
        }
      }, 400)
    }
  }

  return (
    <Dialog isOpen={currentDialog === 'register'} onClose={handleClose}>
      <div className={controlStyles.top}>
        <h1 className={controlStyles.title}>Regisztráció</h1>
        <Button className={controlStyles.button} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>

      <div className={controlStyles.middle}>
        <div className={styles.contentContainer}>
          <TextField
            className={controlStyles.textField}
            disabled={loading}
            label='E-mail'
            variant='outlined'
            type='email'
            placeholder='minta.peter@domain.com'
            error={emailError}
            onChange={(x) => onEmailChange(x.target.value)}
          />

          <TextField
            className={controlStyles.textField}
            disabled={loading}
            label='Jelszó'
            variant='outlined'
            type='password'
            placeholder='••••••••••••'
            error={passwordError}
            onChange={(x) => onPasswordChange(x.target.value)}
          />

          <div className={styles.formGroupTitle}>Fiók típusa</div>
          <RadioGroup
            className={styles.accountTypeSelector}
            value={accountType}
            onChange={handleAccountTypeChange}
          >
            <FormControlLabel
              value='user'
              control={<Radio color='primary' />}
              label='Szülőként regisztrálok'
            />
            <FormControlLabel
              value='doctor'
              control={<Radio color='primary' />}
              label='Orvosként regisztrálok'
            />
          </RadioGroup>

          <Collapse in={accountType === 'doctor'}>
            <TextField
              className={controlStyles.textField}
              disabled={loading}
              label='Pecsétszám'
              variant='outlined'
              type='text'
              placeholder='12345'
              error={verificationIDError}
              onChange={(x) => onVerificationIDChange(x.target.value)}
            />
            <p className={controlStyles.note}>
              Pecsétszámát hitelesítési célból összevetjük a Magyar Orvosi Kamara nyilvántartásával.
            </p>
          </Collapse>

          <div className={styles.formGroupTitle}>Nyilatkozatok</div>

          <FormGroup className={styles.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={onTermsAcceptedChange}
                  color='primary'
                />
              }
              label={
                <div className={styles.checkboxLabel}>
                  Elfogadom az{' '}
                  <a
                    target='_blank'
                    href='https://static.orvosvalasz.hu/legal/orvosvalasz_aszf_v1.0.pdf'
                  >
                    általános szerződési feltételek
                  </a>
                  et.
                </div>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyPolicyAccepted}
                  onChange={onPrivacyPolicyAcceptedChange}
                  color='primary'
                />
              }
              label={
                <div className={styles.checkboxLabel}>
                  Elfogadom az{' '}
                  <a
                    target='_blank'
                    href='https://static.orvosvalasz.hu/legal/orvosvalasz_adatkezelesi_v1.0.pdf'
                  >
                    adatkezelési tájékoztató
                  </a>
                  ban foglaltakat.
                </div>
              }
            />
          </FormGroup>

          <Collapse
            in={error}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Alert className={controlStyles.alert} severity='error'>
              {<div>{errorText}</div>}
            </Alert>
          </Collapse>
        </div>

        <div className={controlStyles.buttonContainer}>
          <LoaderButton loading={loading} onClick={handleRegister}>
            Regisztráció
          </LoaderButton>
          <Button onClick={handleClose} className={`${controlStyles.button}`}>
            Mégse
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
