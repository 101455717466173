import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { Close as CloseIcon, MailOutline } from '@material-ui/icons'
import { Dialog } from './Dialog'
import { GlobalContext } from 'src/App'
import controlStyles from './DialogControls.module.scss'
import styles from './ConfirmEmailDialog.module.scss'

export const ConfirmEmailDialog: React.FC = () => {
  const { currentDialog, setCurrentDialog, lastEmail } = useContext(GlobalContext)

  function handleClose() {
    setCurrentDialog('none')
  }

  return (
    <Dialog isOpen={currentDialog === 'confirmEmail'} onClose={handleClose}>
      <div className={controlStyles.top}>
        <h1 className={controlStyles.title}>Fiók aktiválása</h1>
        <Button className={controlStyles.button} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>

      <div className={controlStyles.middle}>
        <p className={controlStyles.note} style={{ marginBottom: '0' }}>
          A folytatáshoz aktiválnia kell a fiókját. <br />
          <br />
          Ezzel kapcsolatos teendőit a(z)
        </p>
        <span className={styles.email}>
          <MailOutline className={styles.icon} />
          {lastEmail}
        </span>
        <p className={controlStyles.note}>címre küldött üzenetünkben találja.</p>

        <p className={controlStyles.note} style={{ fontSize: '0.8rem', opacity: 0.6 }}>
          Amennyiben az üzenet 2-3 percen belül nem
          <br /> érkezik meg, ellenőrizze 'spam' mappáját is!
        </p>

        <div className={controlStyles.buttonContainer}>
          <Button onClick={handleClose} className={`${controlStyles.button}`}>
            Bezárás
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
