import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import controlStyles from './DialogControls.module.scss'

interface LoaderButtonProps {
  loading?: boolean
  disabled?: boolean
  onClick: () => void
}

const LoaderButton: React.FC<LoaderButtonProps> = ({ children, loading, onClick, disabled }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={`${controlStyles.button} ${controlStyles.primary} ${
        disabled ? controlStyles.disabled : null
      }`}
      endIcon={
        loading ? (
          <CircularProgress size={'1.25rem'} color='inherit' style={{ marginLeft: '0.25rem' }} />
        ) : undefined
      }
    >
      {children}
    </Button>
  )
}

export default LoaderButton
