import React, { useState, useContext } from 'react'
import { Button } from '@material-ui/core'
import { ExpandMore as ScrollIcon } from '@material-ui/icons'
import { GlobalContext } from 'src/App'

import styles from './HomePage.module.scss'

const Introduction: React.FC = () => {
  return (
    <div
      className={styles.introduction}
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/static_bg_reduced.jpg)` }}
    >
      <div className={styles.inner}>
        <div className={styles.content}>
          <h1>Orvosi diagnózis otthonról</h1>
          <p>
            Előfordult már Önnel, hogy orvosi tanácsra volt szüksége, de tudta, hogy online is
            elegendő lenne?
          </p>
          <p>
            Egészségügyi kérdésekkel kapcsolatosan rendszerünk kérdőív kitöltését követően segít
            Önnek eldönteni, hogy mitévő legyen, szükség van-e orvosi vizsgálatra, és igény esetén
            lehetőséget is kínál arra. Rendszerünk segít Önnek és orvosának az adminisztrációs
            terhek enyhítésében, így igazolásokat, beutalókat, receptet és táppénz-igénylést is el
            tud juttatni háziorvosához. Prémium szolgáltatásunkkal okos eszközeinknek köszönhetően
            otthonában, barátságos körülmények között, egyszerűen elvégezhetőek azok a vizsgálatok,
            amelyekre korábban csak orvosi rendelőben volt lehetőség. Az eredményeket orvosaink
            áttekintik, segítenek felállítani a diagnózist, és terápiát is javasolnak. Amennyiben
            szükség van rá, gyógyszer felírására is sor kerülhet.
          </p>
          <p>
            Az Orvosválasz.hu segítségével minimálisra lehet csökkenteni az orvosi rendelőbe járást,
            elkerülve ezzel bármilyen kockázatot.
          </p>
          {/* <Button disableElevation variant='outlined' className={styles.button}>
            Tudjon meg többet
          </Button> */}
        </div>
        <ScrollIcon className={styles.scrollIcon} />
        <div className={styles.sectionBorder} />
      </div>
    </div>
  )
}

const Footer: React.FC = () => {
  return (
    <footer>
      <div className={styles.sectionBorder} />
      <h2>OrvosVálasz</h2>
      <div className={styles.content}>
        <div className={styles.links}>
          <a target='_blank' href='https://static.orvosvalasz.hu/legal/orvosvalasz_aszf_v1.0.pdf'>
            Általános szerződési feltételek
          </a>
          <a
            target='_blank'
            href='https://static.orvosvalasz.hu/legal/orvosvalasz_adatkezelesi_v1.0.pdf'
          >
            Adatkezelési nyilatkozat
          </a>
        </div>
        <div className={styles.copyright}>
          &copy; OrvosVálasz {new Date().getFullYear()}. Minden jog fenntartva.
        </div>
      </div>
    </footer>
  )
}

export const HomePage: React.FC = () => {
  const ctx = useContext(GlobalContext)

  return (
    <>
      <Introduction />
      <div className={styles.mainContent}>
        <section id='firstSection' className={styles.inverted}>
          <div className={styles.imageContainer}>
            <img src={`${process.env.PUBLIC_URL}/img/checkup.svg`} />
          </div>
          <span>
            <h1>Panaszok lehetséges okainak kiderítése</h1>
            <p>
              Rendszerünkben regisztrációt követően, fennálló panaszokra keresheti a legvalószínűbb
              okot. Egy kérdéssor kitöltését követően a megadott információ alapján a mai tudásnak
              megfelelően kaphat iránymutatást arról, hogy mi lehet panaszának a hátterében és merre
              kell elindulni a kivizsgálás vagy gyógyulás útján, illetve ha sürgősségi állapot áll
              fenn és mentőt vagy sürgősségi osztályt kell azonnal felkeresni.
            </p>
          </span>
        </section>

        <section>
          <div className={styles.imageContainer}>
            <img src={`${process.env.PUBLIC_URL}/img/routing.svg`} />
          </div>
          <span>
            <h1>Betegirányítás</h1>
            <p>
              A panaszok és a legvalószínűbb ok kiderítését (feltételezhető diagnózist) követően
              rendszerünkkel egy pontosabb képet kaphat arról, hogy mi a folyamata a panasz
              kivizsgálásának, milyen vizsgálatok elvégzése lehet szükséges, mely szakembereket kell
              felkeresni, illetve melyik intézetekben van lehetőség elvégezni.
            </p>
          </span>
        </section>

        <section className={styles.inverted}>
          <div className={styles.imageContainer}>
            <img src={`${process.env.PUBLIC_URL}/img/administration.svg`} />
          </div>
          <span>
            <h1>Adminisztratív ügyek intézése gyorsan, nem időponthoz kötve</h1>
            <p>
              Az Orvosválasz lehetőséget nyújt arra, hogy a szükséges adminisztrációs terheket
              orvosával online, rendelési időn kívül is intézhesse. Ennek köszönhetően a szükséges
              recept, beutaló vagy igazolás már akár a rendelés elején is készen lehet és Ön online
              formában is hozzájut.
            </p>
          </span>
        </section>

        <section>
          <div className={styles.imageContainer}>
            <img src={`${process.env.PUBLIC_URL}/img/home-comfort.svg`} />
          </div>
          <span>
            <h1>Fizikális vizsgálat otthona kényelméből</h1>
            <p>
              Prémium szolgáltatásunk keretén belül Ön megrendelheti otthonába a 21. századi
              technológia eszközeit, amellyel az eszközök biztosítják Önnek, hogy saját kanapéján
              megvizsgálhassa gyermekét vagy egyéb hozzátartozóját. Ezeket az információkat
              továbbítjuk az Ön által kiválasztott vagy elérhető orvosnak, aki online konzultáció
              keretében tud Önnek tanácsot adni, diagnózist felállítani és szükség esetén gyógyszert
              javasolni.
            </p>
          </span>
        </section>

        <section className={styles.inverted}>
          <div className={styles.imageContainer}>
            <img src={`${process.env.PUBLIC_URL}/img/tools.svg`} />
          </div>
          <span>
            <h1>Eszközeink</h1>
            <p className={styles.listItem}>
              Digitális lázmérő, amely lehetőséget ad a testhő megfelelő mérésére.
            </p>
            <p className={styles.listItem}>
              Digitális sztetoszkóp, amellyel lehetőség nyílik felvenni a légzés és a szívverés
              hangját, így azok egy rendszeren keresztül távolból is értékelhető hallgatózást
              nyújtanak az orvosnak.
            </p>
            <p className={styles.listItem}>
              Digitális video otoszkóp, amellyel felvételt lehet készíteni a fül és a száj aktuális
              állapotáról, így a fertőző vagy egyéb betegségeket észre lehet venni, fogak állapotát
              előzetesen fel lehet mérni.
            </p>
            <p className={styles.listItem}>
              Pulzoximéter, amellyel a szervezet oxigén ellátottságát és pulzusát tudjuk
              számszerűsíteni.
            </p>
          </span>
        </section>
      </div>
      <Footer />
    </>
  )
}
