import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  NavBar,
  Menu,
  LoginDialog,
  RegisterDialog,
  ResetPasswordDialog,
  UpdatePasswordDialog,
  ConfirmEmailDialog,
} from 'src/components'

import styles from './PageBase.module.scss'

export const PageBase: React.FC = ({ children }) => {
  const [navStyle, setNavStyle] = useState<'transparent' | 'opaque'>('transparent')
  const [menuOpen, setMenuOpen] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const history = useHistory()

  const navStyleScrollThreshold = 10

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // Document verification ID
    const params = new URLSearchParams(window.location.search)
    const documentID = params.get('document-id')
    if (documentID) history.replace(`/document/${documentID}`)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (!menuOpen) {
      updateNavStyle()
    }
  }, [scrollY])

  function updateNavStyle() {
    if (scrollY <= navStyleScrollThreshold && navStyle !== 'transparent') {
      setNavStyle('transparent')
    } else if (scrollY > navStyleScrollThreshold && navStyle !== 'opaque') {
      setNavStyle('opaque')
    }
  }

  function handleScroll() {
    setScrollY(window.scrollY)
  }

  function handleNavLogoClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (menuOpen) {
      setMenuOpen(false)
    }
  }

  function toggleMenuState() {
    if (menuOpen) {
      setMenuOpen(false)
      updateNavStyle()
    } else {
      setMenuOpen(true)
      setNavStyle('opaque')
    }
  }

  return (
    <>
      <NavBar
        style={navStyle}
        menuOpen={menuOpen}
        onLogoClick={handleNavLogoClick}
        onMenuClick={toggleMenuState}
      />
      <Menu isOpen={menuOpen} onItemSelected={() => setMenuOpen(false)} />

      <LoginDialog />
      <RegisterDialog />
      <ResetPasswordDialog />
      <UpdatePasswordDialog />
      <ConfirmEmailDialog />

      <div
        onClick={() => {
          if (menuOpen) toggleMenuState()
        }}
      >
        {children}
      </div>
    </>
  )
}
