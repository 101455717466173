import { config } from 'src/config'

function construct(endpoint: string) {
  return `${config.apiURL}${endpoint}`
}

const urls = {
  login: construct('/login'),
  register: construct('/register'),
  auth: construct('/auth'),
  resetPassword: construct('/reset-password'),
  updatePassword: construct('/update-password'),
  verify: construct('/verify-user'),
  validateDocument: construct('/media/document/validate'),
  document: construct('/media/document'),
}

export default urls
