export type LoginResponse = {
  status: {
    result: 'error' | 'ok'
    message: string
  }
  sessionKey: string | null
}

export type GenericResponse = {
  status: {
    result: 'error' | 'ok'
    message: string
  }
}

export const loginError: LoginResponse = {
  status: {
    result: 'error',
    message: 'API unavailable',
  },
  sessionKey: null,
}
