import React, { useContext, useState } from 'react'
import { Button, TextField, Collapse } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { Dialog } from './Dialog'
import { GlobalContext } from 'src/App'
import { API } from 'src/api'
import LoaderButton from './LoaderButton'
import controlStyles from './DialogControls.module.scss'
import styles from './ResetPasswordDialog.module.scss'

export const ResetPasswordDialog: React.FC = () => {
  const { currentDialog, setCurrentDialog } = useContext(GlobalContext)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [loading, setLoading] = useState(false)

  function handleClose() {
    setCurrentDialog('none')
    setTimeout(() => {
      setError(false)
      setErrorText('')
      setEmail('')
      setEmailError(false)
      setLoading(false)
    }, 200)
  }

  function onEmailChange(v: string) {
    setEmail(v)
    setEmailError(false)
  }

  function validateFields() {
    const emailRegex = /.+@.+..+/
    if (!email.match(emailRegex)) {
      setEmailError(true)
      setErrorText('Helytelen e-mail cím!')
      setError(true)
      return false
    }
    return true
  }

  async function handleResetPassword() {
    if (!loading && validateFields()) {
      setError(false)
      setEmailError(false)
      setLoading(true)
      const res = await API.Account.resetPassword(email)
      setTimeout(() => {
        setLoading(false)
        handleClose()
      }, 400)
    }
  }

  return (
    <Dialog isOpen={currentDialog === 'resetPassword'} onClose={handleClose}>
      <div className={controlStyles.top}>
        <h1 className={controlStyles.title}>Jelszó visszaállítása</h1>
        <Button className={controlStyles.button} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>

      <div className={controlStyles.middle}>
        <TextField
          className={controlStyles.textField}
          disabled={loading}
          label='E-mail'
          variant='outlined'
          type='email'
          placeholder='minta.peter@domain.com'
          error={emailError}
          onChange={(x) => onEmailChange(x.target.value)}
        />

        <p className={controlStyles.note}>
          Adja meg a regisztrációkor használt e-mail címet, majd kövesse az e-mail-ben kapott
          utasításokat.
        </p>

        <Collapse in={error}>
          <Alert className={controlStyles.alert} severity='error'>
            {<div>{errorText}</div>}
          </Alert>
        </Collapse>

        <div className={controlStyles.buttonContainer}>
          <LoaderButton loading={loading} onClick={handleResetPassword}>
            Jelszó visszaállítása
          </LoaderButton>
          <Button onClick={handleClose} className={`${controlStyles.button}`}>
            Mégse
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
