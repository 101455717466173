import React, { useState, useEffect } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { ErrorOutline, InputOutlined } from '@material-ui/icons'
import { Document as PDF, Page } from 'react-pdf/dist/esm/entry.webpack'
import { API } from 'src/api'
import styles from './DocumentPage.module.scss'

const Introduction: React.FC = () => {
  const history = useHistory()

  function returnToHome() {
    history.push('/')
  }

  return (
    <div
      className={styles.introduction}
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/static_bg_reduced.jpg)` }}
    >
      <div className={styles.inner}>
        <div className={styles.content}>
          <h1>Dokumentum hitelesítése</h1>
          <p>
            Ezen az oldalon a beszkennelt dokumentum hitelességét ellenőrizheti. Kérjük vesse össze
            az Ön példányán, illetve az alább megjelenített eredeti dokumentumon szereplő adatokat!
            A dokumentum hiteles, ha az adatokban semmilyen eltérést nem talál.
          </p>
          <Button
            onClick={returnToHome}
            disableElevation
            variant='outlined'
            className={styles.button}
          >
            Vissza a főoldalra
          </Button>
        </div>
        <div className={styles.sectionBorder} />
      </div>
    </div>
  )
}

const Footer: React.FC = () => {
  return (
    <footer>
      <div className={styles.sectionBorder} />
      <h2>OrvosVálasz</h2>
      <div className={styles.content}>
        <div className={styles.links}>
          <a target='_blank' href='https://static.orvosvalasz.hu/legal/orvosvalasz_aszf_v1.0.pdf'>
            Általános szerződési feltételek
          </a>
          <a
            target='_blank'
            href='https://static.orvosvalasz.hu/legal/orvosvalasz_adatkezelesi_v1.0.pdf'
          >
            Adatkezelési nyilatkozat
          </a>
        </div>
        <div className={styles.copyright}>
          &copy; OrvosVálasz {new Date().getFullYear()}. Minden jog fenntartva.
        </div>
      </div>
    </footer>
  )
}

export const DocumentPage: React.FC = () => {
  const documentID = useParams<{ documentID: string }>().documentID
  const [rem, setRem] = useState<number>(15)
  const [loaded, setLoaded] = useState(false)
  const [isValid, setIsValid] = useState<boolean | null>(null)

  useEffect(() => {
    if (documentID) validateDocumentID()
    setRem(parseInt(getComputedStyle(document.documentElement).fontSize))
  }, [documentID])

  async function validateDocumentID() {
    const r = await API.Document.validateDocument(documentID)
    setIsValid(r.status.result === 'ok')
  }

  function downloadDocument() {
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = API.Document.documentURL(documentID)
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  let content = null

  switch (isValid) {
    case true:
      content = (
        <>
          <PDF
            onLoadError={console.log}
            onLoadSuccess={() => setLoaded(true)}
            file={API.Document.documentURL(documentID)}
            className={[styles.pdfDisplay, loaded ? styles.loaded : null].join(' ')}
            loading={<CircularProgress size={75} />}
          >
            <Page pageNumber={1} width={50 * rem} />
          </PDF>
          <Button
            onClick={downloadDocument}
            variant='outlined'
            startIcon={<InputOutlined />}
            className={styles.downloadButton}
          >
            Dokumentum megnyitása
          </Button>
        </>
      )
      break
    case false:
      content = (
        <div className={styles.invalid}>
          <ErrorOutline className={styles.icon} />A beszkennelt dokumentum azonosító nem található a
          rendszerben.
          <span>{documentID}</span>
        </div>
      )
      break
  }

  return (
    <>
      <Introduction />
      <div className={styles.mainContent}>{content}</div>
      <Footer />
    </>
  )
}
