import React, { useContext, useState } from 'react'
import { Button, TextField, Collapse } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { SHA256 } from 'crypto-js'
import { Dialog } from './Dialog'
import { GlobalContext } from 'src/App'
import { config } from 'src/config'
import { API } from 'src/api'
import LoaderButton from './LoaderButton'
import controlStyles from './DialogControls.module.scss'
import styles from './UpdatePasswordDialog.module.scss'

export const UpdatePasswordDialog: React.FC = () => {
  const { currentDialog, setCurrentDialog, passwordResetToken } = useContext(GlobalContext)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [loading, setLoading] = useState(false)

  function handleClose() {
    setCurrentDialog('none')
    setTimeout(() => {
      setError(false)
      setErrorText('')
      setPassword('')
      setPasswordError(false)
      setLoading(false)
    }, 200)
  }

  function onPasswordChange(v: string) {
    setPassword(v)
    setPasswordError(false)
  }

  function validateFields() {
    if (password.length < 8) {
      setPasswordError(true)
      setErrorText('Legalább 8 karakteres jelszó szükséges!')
      setError(true)
      return false
    }
    return true
  }

  async function handleUpdatePassword() {
    if (!loading && validateFields()) {
      setError(false)
      setPasswordError(false)
      setLoading(true)

      const passwordHash = SHA256(password).toString()
      const res = await API.Account.updatePassword(passwordResetToken, passwordHash)
      setTimeout(() => {
        setLoading(false)
        if (res.status.result === 'error') {
          setError(true)
          setErrorText('Lejárt visszaállítási kulcs!')
        } else {
          let date = new Date()
          date.setFullYear(date.getFullYear() + 1)
          document.cookie =
            'session_key=' +
            res.sessionKey +
            '; expires=' +
            date.toUTCString() +
            '; path=/; domain=orvosvalasz.hu'
          window.location.replace(config.appURL)
        }
      }, 400)
    }
  }

  return (
    <Dialog isOpen={currentDialog === 'updatePassword'} onClose={handleClose}>
      <div className={controlStyles.top}>
        <h1 className={controlStyles.title}>Új jelszó beállítása</h1>
        <Button className={controlStyles.button} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>

      <div className={controlStyles.middle}>
        <TextField
          className={controlStyles.textField}
          disabled={loading}
          label='Jelszó'
          variant='outlined'
          type='password'
          placeholder='••••••••••••'
          error={passwordError}
          onChange={(x) => onPasswordChange(x.target.value)}
        />

        <p className={controlStyles.note}>
          A biztonság növelése érdekében legalább 8 karakter hosszú jelszó megadása szükséges.
        </p>

        <Collapse in={error}>
          <Alert className={controlStyles.alert} severity='error'>
            {<div>{errorText}</div>}
          </Alert>
        </Collapse>

        <div className={controlStyles.buttonContainer}>
          <LoaderButton loading={loading} onClick={handleUpdatePassword}>
            Jelszó beállítása
          </LoaderButton>
          <Button onClick={handleClose} className={`${controlStyles.button}`}>
            Mégse
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
